
require('overlayscrollbars');

window.moment = require('moment');
window.moment.locale('th')
window.moment.updateLocale('th',{
    eras: [{
        since: '0000-01-01',
        until: +Infinity,
        offset: 543,
        name: 'Buddhist Era',
        narrow: 'BE',
        abbr: 'BE'
    }],
});

require('bootstrap');

window.$ = window.jQuery = require('jquery');

require('../../vendor/almasaeed2010/adminlte/dist/js/adminlte');

require('select2/dist/js/select2.js');
require('./tempusdominus-bootstrap-4.js');
// require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min.js');


require('datatables.net-dt');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-select-bs4');
require('datatables.net-searchpanes-bs4');

require('../../public/vendor/datatables/buttons.server-side.js');

global.bsCustomFileInput = require('bs-custom-file-input/dist/bs-custom-file-input.js');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
